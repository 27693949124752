import {
	createContext,
	type PropsWithChildren,
	useContext,
	useMemo,
} from 'react';

export type FeatureFlagProviderProps = {
	featureToggleDefinitions: {
		description?: string;
		enabled: boolean;
		name: string;
	}[];
};

export type FeatureFlagProviderData = FeatureFlagProviderProps;

export const featureFlags = {
	DISABLE_BEST_BETS_WIDGET: 'disable-best-bets-widget',
	DISABLE_BEST_BETS_WIDGET_FULL_WIDTH: 'render-best-bets-widget-full-width',
	DISABLE_GOOGLE_TAG_MANAGER: 'disable-google-tag-manager',
	DISABLE_GRID_TAB_STATS: 'disable-grid-tab-stats',
	DISABLE_OFFER_WIDGETS: 'disable-offer-widgets',
	DISABLE_SENTRY: 'disable-sentry',
	DISABLE_VWO: 'disable-vwo',
	SHOW_MYOC_LOGIN: 'show-myoc-login',
	SUBS__PLUS__ALL_WNBA: 'subs__plus__all_wnba',
	SUBS__PLUS__NEW_CATEGORY_GROUPS: 'subs__plus__new_category_groups',
	SUBS__PLUS__PADLOCKING: 'subs__plus__padlocking',
	SUBS__PLUS__TIPSTERS: 'subs__plus__tipsters',
	RENDER_ONBOARDING_AS_MYOC_LOGIN_CTA: 'render-onboarding-as-myoc-login-cta',
};

export const FeatureFlagContext = createContext<FeatureFlagProviderData>(
	undefined as any as FeatureFlagProviderData,
);
FeatureFlagContext.displayName = 'FeatureFlagContext';

export const FeatureFlagProvider = ({
	children,
	featureToggleDefinitions,
	...props
}: PropsWithChildren<FeatureFlagProviderData>) => {
	const value = useMemo(
		() => ({
			featureToggleDefinitions,
		}),
		[featureToggleDefinitions],
	);

	return (
		<FeatureFlagContext.Provider value={value} {...props}>
			{children}
		</FeatureFlagContext.Provider>
	);
};

export const useFeatureFlagContext = () => {
	const context = useContext(FeatureFlagContext);
	if (!context) {
		throw new Error(
			'useFeatureFlagContext can only be used inside of FeatureFlagProvider',
		);
	}

	return context;
};

export function useFeatureFlag<K extends keyof typeof featureFlags>(
	name: (typeof featureFlags)[K],
) {
	const context = useFeatureFlagContext();

	const featureToggle = context.featureToggleDefinitions.find(
		featureToggle => featureToggle.name === name,
	);

	return featureToggle?.enabled ?? false;
}
